<template>
  <div>
    <b-card bg-variant="light" header="Lista Chequeo" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-concepto_uso_suelo"
                        label="Concepto de uso del suelo"
                        label-for="concepto_uso_suelo"
                    >
                        <b-form-select 
                            id="concepto_uso_suelo" 
                            v-model="form.concepto_uso_suelo" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.concepto_uso_suelo==1" >
                    <b-form-group
                        id="group-resolucion_uso_suelo"
                        label="Núm. Resolución uso del suelo"
                        label-for="resolucion_uso_suelo"
                    >
                        <b-form-input
                            id="resolucion_uso_suelo"
                            v-model="form.resolucion_uso_suelo"
                            :disabled="verDetalles?true:false"
                            :required="form.concepto_uso_suelo==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4" v-if="form.concepto_uso_suelo==1">
                    <b-form-group
                        id="group-archivo_uso_suelo"
                        label="Archivo de la licencia de uso de suelo"
                        label-for="archivo_uso_suelo"
                       
                    >
                        <b-form-file
                            id="archivo_uso_suelo"
                            :state="Boolean(form.archivo_uso_suelo)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenUsoSuelo" 
                            v-if=" !cargadoUsoSuelo"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoUsoSuelo" @click="descargarPDF(form.archivo_uso_suelo, 'usoSuelo')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-concesion_aguas"
                        label="Permiso de concesión de aguas"
                        label-for="concesion_aguas"
                    >
                        <b-form-select 
                            id="concesion_aguas" 
                            v-model="form.concesion_aguas" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.concesion_aguas==1" >
                    <b-form-group
                        id="group-resolucion_conce_aguas"
                        label="Núm. Resolución concesión de aguas"
                        label-for="resolucion_conce_aguas"
                    >
                        <b-form-input
                            id="resolucion_conce_aguas"
                            v-model="form.resolucion_conce_aguas"
                            :disabled="verDetalles?true:false"
                            :required="form.concesion_aguas==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-permiso_vertimientos"
                        label="Permiso de vertimientos"
                        label-for="permiso_vertimientos"
                    >
                        <b-form-select 
                            id="permiso_vertimientos" 
                            v-model="form.permiso_vertimientos" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.permiso_vertimientos==1" >
                    <b-form-group
                        id="group-resolucion_vertimientos"
                        label="Núm. Resolución permiso vertimientos"
                        label-for="resolucion_vertimientos"
                    >
                        <b-form-input
                            id="resolucion_vertimientos"
                            v-model="form.resolucion_vertimientos"
                            :disabled="verDetalles?true:false"
                            :required="form.permiso_vertimientos==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-permiso_emisiones"
                        label="Permiso de emisiones atmosféricas"
                        label-for="permiso_emisiones"
                    >
                        <b-form-select 
                            id="permiso_emisiones" 
                            v-model="form.permiso_emisiones" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.permiso_emisiones==1" >
                    <b-form-group
                        id="group-resolucion_emisiones"
                        label="Núm. Resolución permiso emisiones"
                        label-for="resolucion_emisiones"
                    >
                        <b-form-input
                            id="resolucion_emisiones"
                            v-model="form.resolucion_emisiones"
                            :disabled="verDetalles?true:false"
                            :required="form.permiso_emisiones==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-permiso_ocup_cause"
                        label="Permiso de ocupación de cause"
                        label-for="permiso_ocup_cause"
                    >
                        <b-form-select 
                            id="permiso_ocup_cause" 
                            v-model="form.permiso_ocup_cause" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.permiso_ocup_cause==1" >
                    <b-form-group
                        id="group-resolucion_ocup_cause"
                        label="Núm. Resolución permiso ocupación cause"
                        label-for="resolucion_ocup_cause"
                    >
                        <b-form-input
                            id="resolucion_ocup_cause"
                            v-model="form.resolucion_ocup_cause"
                            :disabled="verDetalles?true:false"
                            :required="form.permiso_ocup_cause==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.permiso_ocup_cause==1">
                    <b-form-group
                        id="group-archivo_ocup_cause"
                        label="Archivo de Ocupación Cause"
                        label-for="archivo_ocup_cause"
                       
                    >
                        <b-form-file
                            id="archivo_ocup_cause"
                            :state="Boolean(form.archivo_ocup_cause)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenOcupCause" 
                            v-if=" !cargadoOcupCause"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoOcupCause" @click="descargarPDF(form.archivo_ocup_cause, 'OcupacionCause')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-depa_gest_ambiental"
                        label="Permiso departamental gestion ambiental"
                        label-for="depa_gest_ambiental"
                    >
                        <b-form-select 
                            id="depa_gest_ambiental" 
                            v-model="form.depa_gest_ambiental" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.depa_gest_ambiental==1" >
                    <b-form-group
                        id="group-resolucion_gestion_amb"
                        label="Núm. Resolución permiso dep. gestión amb."
                        label-for="resolucion_gestion_amb"
                    >
                        <b-form-input
                            id="resolucion_gestion_amb"
                            v-model="form.resolucion_gestion_amb"
                            :disabled="verDetalles?true:false"
                            :required="form.depa_gest_ambiental==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-estudio_ambiental"
                        label="Estudio de impacto ambiental"
                        label-for="estudio_ambiental"
                    >
                        <b-form-select 
                            id="estudio_ambiental" 
                            v-model="form.estudio_ambiental" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.estudio_ambiental==1" >
                    <b-form-group
                        id="group-resolucion_estudio_amb"
                        label="Núm. Resolución de estudio ambiental"
                        label-for="resolucion_estudio_amb"
                    >
                        <b-form-input
                            id="resolucion_estudio_amb"
                            v-model="form.resolucion_estudio_amb"
                            :disabled="verDetalles?true:false"
                            :required="form.estudio_ambiental==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.estudio_ambiental==1">
                    <b-form-group
                        id="group-archivo_est_ambiental"
                        label="Archivo de la licencia de estudio amb."
                        label-for="archivo_est_ambiental"
                       
                    >
                        <b-form-file
                            id="archivo_est_ambiental"
                            :state="Boolean(form.archivo_est_ambiental)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenEstAmbiental" 
                            v-if=" !cargadoEstAmbiental"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoEstAmbiental" @click="descargarPDF(form.archivo_est_ambiental, 'estudioAmbiental')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-pgirs_respel"
                        label="PGIRS - Residuos Peligrosos - RESPEL"
                        label-for="pgirs_respel"
                    >
                        <b-form-select 
                            id="pgirs_respel" 
                            v-model="form.pgirs_respel" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.pgirs_respel==1" >
                    <b-form-group
                        id="group-resolucion_pgirs_respel"
                        label="Núm. Resolución PGIRS - RESPEL"
                        label-for="resolucion_pgirs_respel"
                    >
                        <b-form-input
                            id="resolucion_pgirs_respel"
                            v-model="form.resolucion_pgirs_respel"
                            :disabled="verDetalles?true:false"
                            :required="form.pgirs_respel==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-otros_permisos"
                        label="Permisos y/o Licenc. CAR y/o ANLA"
                        label-for="otros_permisos"
                    >
                        <b-form-select 
                            id="otros_permisos" 
                            v-model="form.otros_permisos" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.otros_permisos==1" >
                    <b-form-group
                        id="group-resolucion_otros"
                        label="Núm. Resoluciones permisos y licencias"
                        label-for="resolucion_otros"
                    >
                        <b-form-input
                            id="resolucion_otros"
                            v-model="form.resolucion_otros"
                            :disabled="verDetalles?true:false"
                            :required="form.otros_permisos==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.otros_permisos==1">
                    <b-form-group
                        id="group-archivo_otros"
                        label="Archivo otras licencias"
                        label-for="archivo_otros"
                       
                    >
                        <b-form-file
                            id="archivo_otros"
                            :state="Boolean(form.archivo_otros)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenOtros" 
                            v-if=" !cargadoOtros"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoOtros" @click="descargarPDF(form.archivo_otros, 'OtraLicencia')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                    <b-form-group
                        id="group-plan_contigencia"
                        label="Plan contingencias y emergencias"
                        label-for="plan_contigencia"
                    >
                        <b-form-select 
                            id="plan_contigencia" 
                            v-model="form.plan_contigencia" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                            <b-form-select-option value="2">No Aplica</b-form-select-option>
                            <b-form-select-option value="3">Pendiente</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.plan_contigencia==1" >
                    <b-form-group
                        id="group-resolucion_plan_conti"
                        label="Núm. Resoluciones del plan de contingencia"
                        label-for="resolucion_plan_conti"
                    >
                        <b-form-input
                            id="resolucion_plan_conti"
                            v-model="form.resolucion_plan_conti"
                            :disabled="verDetalles?true:false"
                            :required="form.plan_contigencia==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="4" v-if="form.plan_contigencia==1">
                    <b-form-group
                        id="group-archivo_plan_conti"
                        label="Archivo Plan Contingencia"
                        label-for="archivo_plan_conti"
                       
                    >
                        <b-form-file
                            id="archivo_plan_conti"
                            :state="Boolean(form.archivo_plan_conti)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenPlanConti" 
                            v-if=" !cargadoPlanConti"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoPlanConti" @click="descargarPDF(form.archivo_plan_conti, 'PlanContingencia')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'ListaChequeo',
    data() {
      return {
        editar:false,
        cargadoUsoSuelo: false,
        cargadoOcupCause: false,
        cargadoEstAmbiental: false,
        cargadoOtros: false,
        cargadoPlanConti: false,
        form: {
            id: null,
            concepto_uso_suelo: null,
            resolucion_uso_suelo: null,
            archivo_uso_suelo: null,
            concesion_aguas:null,
            resolucion_conce_aguas:null,
            permiso_vertimientos:null,
            resolucion_vertimientos: null,
            permiso_emisiones: null,
            resolucion_emisiones:null,
            permiso_ocup_cause: null,
            resolucion_ocup_cause: null,
            archivo_ocup_cause: null,
            depa_gest_ambiental:null,
            resolucion_gestion_amb: null,
            estudio_amiental: null,
            resolucion_estudio_amb: null,
            archivo_est_ambiental: null,
            pgirs_respel: null,
            resolucion_pgirs_respel: null,
            otros_permisos: null,
            resolucion_otros: null,
            archivo_otros: null,
            plan_contigencia: null,
            resolucion_plan_conti: null,
            archivo_plan_conti: null,
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarListaChequeo(this.visitaEnGestion.id)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultListaChequeo', 'listaChequeoCargada' ]),
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearListaChequeo', 'cargarListaChequeoById', 'editarListaChequeo']),

        async cargarListaChequeo(id){
            this.activarLoading(true)
            try{
                await this.cargarListaChequeoById(id)
                console.log('esto es lo cargado', this.listaChequeoCargada[0])
                if(this.listaChequeoCargada.length>0){
                    this.editar=true
                    this.form=this.listaChequeoCargada[0]
                    this.cargadoUsoSuelo =  this.form.archivo_uso_suelo?true:false
                    this.cargadoOcupCause =  this.form.archivo_ocup_cause?true:false
                    this.cargadoEstAmbiental =  this.form.archivo_est_ambiental?true:false
                    this.cargadoOtros =  this.form.archivo_otros?true:false
                    this.cargadoPlanConti =  this.form.archivo_plan_conti?true:false
                    
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        imagenUsoSuelo(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'usoSuelo')
        },
        imagenOcupCause(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'ocupCause')
        },
        imagenEstAmbiental(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'estAmbiental')
        },
        imagenOtros(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'otros')
        },
        imagenPlanConti(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'planConti')
        },

        crearBase64(file, lugar){
            if(file){
                let reader = new FileReader();
                reader.onload=(e)=>{
                    let archivo = e.target.result
                    let base = archivo.split('base64,')
                    let pdf = base[1]

                    if(lugar=='usoSuelo'){
                        this.form.archivo_uso_suelo = pdf;
                    }
                    if(lugar=='ocupCause'){
                        this.form.archivo_ocup_cause = pdf;
                    }
                    if(lugar=='estAmbiental'){
                        this.form.archivo_est_ambiental = pdf;
                    }
                    if(lugar=='otros'){
                        this.form.archivo_otros = pdf;
                    }
                    if(lugar=='planConti'){
                        this.form.archivo_plan_conti = pdf;
                    }
                }
                reader.readAsDataURL(file)
            }else{
                     if(lugar=='usoSuelo'){
                        this.form.archivo_uso_suelo = null;
                    }
                    if(lugar=='ocupCause'){
                        this.form.archivo_ocup_cause = null;
                    }
                    if(lugar=='estAmbiental'){
                        this.form.archivo_est_ambiental = null;
                    }
                    if(lugar=='otros'){
                        this.form.archivo_otros = null;
                    }
                    if(lugar=='planConti'){
                        this.form.archivo_plan_conti = null;
                    }
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Lista de Chequeo, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                listachequeo: this.form
            }

             try{
                 if(this.editar){
                     await this.editarListaChequeo(dataGuardar)
                 }else{
                     await this.crearListaChequeo(dataGuardar)
                 }
                if(this.resultListaChequeo.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarListaChequeo(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 

        descargarPDF(pdf, nombre) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `licencia_${nombre}.pdf`;
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
    }
  }
</script>

<style scoped>
    
</style>